module SharedComponents.ReactCalendar
open Fable.Core
open Fable.React
open Fable.React.Props
open Fable.Core.JsInterop
open System

let de : obj = importDefault "moment/locale/de"

[<Import("Views", from="react-big-calendar")>]
let rbcView : unit = jsNative

[<Import("momentLocalizer", from="react-big-calendar")>]
let momentLocalizer (obj): obj = jsNative

let moment : obj = importDefault "moment"

let localization : obj =
    let currentMoment = moment
    currentMoment?locale <- "de"
    momentLocalizer(currentMoment)

type Event =
    { id : System.Guid
      title : string
      start : DateTime
      ``end`` : DateTime
      allDay : bool }

type Messages =
    { allDay : string
      previous : string
      next : string
      today : string
      month : string
      week : string
      day : string
      agenda : string
      date : string
      time : string
      event : string
      noEventsInRange : string
      showMore : int -> string
       }

let getMessages =
    { allDay = "ganztägig"
      previous = "<"
      next = ">"
      today = "Heute"
      month = "Monat"
      week = "Woche"
      day = "Tag"
      agenda = "Agenda"
      date = "Datum"
      time = "Zeit"
      event = "Ereignis"
      noEventsInRange = "Es liegen keine Termine vor."
      showMore = (fun x -> sprintf "mehr anzeigen %i" x) }

type CommonProps =
    | Localizer of obj
    | Events of Event array
    | StartAccessor of string
    | EndAccessor of string
    | Style of obj
    | Messages of Messages
    | OnSelectEvent of (Event -> unit)
    | RangeStartMin of DateTime
    | Popup

let inline calendar (props : CommonProps array) =
    ofImport "Calendar" "react-big-calendar" (keyValueList CaseRules.LowerFirst props) []

let defaultCalendar events onSelect =
    div [ Class "flex-grow-1 d-flex"
          Id "calendar-component" ]
        [ calendar [| CommonProps.Localizer localization
                      CommonProps.Popup
                      CommonProps.StartAccessor "start"
                      CommonProps.EndAccessor "end"
                      CommonProps.Messages getMessages
                      CommonProps.Events events
                      CommonProps.OnSelectEvent onSelect
                      CommonProps.RangeStartMin DateTime.UtcNow |] ]