module Configuration.Locations.Helper

open Configuration.Locations.Types
open Shared.Address

let isLocationInEditMode (location : Location) (formState : FormState) =
    match formState with
    | View -> false
    | New -> location.Id = (System.Guid.Empty |> Shared.LocationId)
    | Edit editLocationId -> location.Id = editLocationId
