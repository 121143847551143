module MyTask.State

open Elmish
open Thoth.Json
open MyTask.Types
open Shared
open System
open Shared.TaskTypes
open Shared.TaskTypes.Helper

// let getMyTasksCmd =
//     Cmd.OfPromise.either Communication.getRequest<MyTasksResponse> "/api/tasks/my" MyTasksFetched FetchError

let init user : Model * Cmd<Msg> =
    let initialModel =
        { UserData = user
        //   MyTasks = [ ]
          CurrentSelectedDate = DateTime.UtcNow.Date
        //   SelectedMyTask = None
           }
    initialModel, Cmd.none // getMyTasksCmd

let initDetailView taskId user : Model * Cmd<Msg> =
    let initialModel =
        { UserData = user
        //   MyTasks = [ ]
          CurrentSelectedDate = DateTime.UtcNow.Date
        //   SelectedMyTask = None
           }
    initialModel, Cmd.none // getMyTasksCmd

let update (msg:Msg) model : Model*Cmd<Msg> =
    match msg with
    // | MyTasksFetched response ->
    //    let myTasks = response.Tasks
    //                  |> List.groupBy executionDate
    //                  |> List.sortByDescending (fun (d, dx) -> d)
    //    { model with MyTasks = myTasks }, Cmd.none
    | ShowPrevDay ->
        let currentSelectedDate =
            (model.CurrentSelectedDate.AddDays(-1.))
        { model with CurrentSelectedDate = currentSelectedDate }, Cmd.none
    | ShowNextDay ->
        let currentSelectedDate =
            (model.CurrentSelectedDate.AddDays(+1.))
        { model with CurrentSelectedDate = currentSelectedDate }, Cmd.none
    | FetchError e ->
        model, ErrorHandling.handleFetchError e
