module UserManagement.FormValidation

open Shared
open UserManagement.Types
open Validation
open System

let notValid =
    { Validations = []
      FormValid = NotValid "" }

let create key value : ValidationKeyValue =
    { Key = key
      Value = value }

let userPassword (user : NewUserDto) =
    if not (String.IsNullOrWhiteSpace user.Password) && not (String.IsNullOrWhiteSpace user.SecondPassword) && (user.SecondPassword = user.Password) then
        create "userpass" ValidationState.Valid
    else create "userpass" (ValidationState.NotValid "Passwort muss übereinstimmen und darf nicht leer sein.")

let userEmail model (user : NewUserDto) (currentUserId : int option) =
    let isEmailDuplicated =
        model.Users
        |> List.filter (fun u ->
            match currentUserId with
            | Some id -> u.Id <> id
            | None -> true)
        |> List.map (fun f -> f.Email)
        |> List.contains (user.User.Email)
    if not (String.IsNullOrWhiteSpace user.User.Email) && not isEmailDuplicated then
        create "useremail" ValidationState.Valid
    else create "useremail" (ValidationState.NotValid "E-Mail Adresse muss ausgefüllt sein oder existiert bereits.")

let userRole (user : NewUserDto) =
    if not user.Roles.IsEmpty then
        create "userroles" ValidationState.Valid
    else create "userroles" (ValidationState.NotValid "Ein Rolle muss ausgewählt sein.")

let validateNewForm (model : Model) (userForm : NewUserForm) : FormValidation =
    let user = userForm.User
    let newValidations =
        [ userPassword user
          userEmail model user None
          userRole user ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = newValidations
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid }

let validateBaseInformation (model : Model) (userForm : EditUserForm) : FormValidation =
    let user = userForm.User
    let newValidations =
        [ userEmail model user (Some user.User.Id) ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = newValidations
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid }

let validatePassword (userForm : EditUserForm) : FormValidation =
    let user = userForm.User
    let newValidations =
        [ userPassword user ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = newValidations
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid }

let validateRoles (userForm : EditUserForm) : FormValidation =
    let user = userForm.User
    let newValidations =
        [ userRole user ]
    let isNotValid =
        newValidations
        |> List.exists (fun r ->
            match r.Value with
            | ValidationState.Valid -> false
            | ValidationState.NotValid _ -> true)
    { Validations = newValidations
      FormValid = if isNotValid then ValidationState.NotValid "" else ValidationState.Valid }
