module SharedComponents.ReactGoogleMap

open Fable.Core
open Fable.React
open Fable.Core.JsInterop
open Fable.React.Props

type MarkerProps =
    { lat : float
      lng : float
      text : string}

let markerElement className marker : ReactElement =
    i [ Class ("fas fa-map-marker " + className) ]
        [ ]

let markerElementWithHoverContent className onMouseEnter onMouseLeave onClick content marker : ReactElement =
    div [ ]
        [ i [ Class ("fas fa-map-marker " + className)
              onMouseEnter
              onMouseLeave
              onClick ]
            [ ]
          content ]

type Center =
    { lat : float
      lng : float }

type GoogleKey =
    { key : string }

type CommonProps =
    | BootstrapURLKeys of GoogleKey
    | Center of float array
    | Zoom of int
    | YesIWantToUseGoogleMapApiInternals of bool
    | OnGoogleApiLoaded of (obj -> unit)

let handleApiLoaded latitudes longitudes (a) : unit =
    let latLngs = longitudes |> List.zip latitudes
    let maps = a?maps
    let bounds  = createNew maps?LatLngBounds ()
    latLngs
    |> List.iter (fun (lat, lng) ->
        let loc = createNew maps?LatLng (lat, lng)
        bounds?extend(loc))
    a?map?fitBounds bounds
    a?map?panToBounds bounds
let getInitialList (values : float list) (value) =
    if values.IsEmpty then [value] else values

let calculateCenter (latitudes : float list) (longitudes : float list) =
    let latMax = (getInitialList latitudes 48.703159) |> List.max
    let latMin = (getInitialList latitudes 48.703159) |> List.min
    let lngMax = (getInitialList longitudes 9.654000) |> List.max
    let lngMin = (getInitialList longitudes 9.654000) |> List.min
    (latMax + latMin) / 2., (lngMax + lngMin) / 2.

let inline marker markerElement (markerProps : MarkerProps) = ofFunction markerElement markerProps []

let inline googleMap (props : CommonProps array) (elems : ReactElement list) =
    ofImport "default" "google-map-react" (keyValueList CaseRules.LowerFirst props) elems