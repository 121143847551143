module Shared.FreeOrder

open System
open Shared
open Address
open TaskTypes
open Entity
open RentOrder

type FreeOrderState =
    | Undisposed
    | Disposed
    | Completed

type FreeOrder =
    { Id : FreeOrderId
      State : FreeOrderState
      Title : string
      Description : string
      PlannedExecutionDate : DateTime
      ExecutionDate : DateTime
      ExecutionAddress : Address
      Tasks : FreeOrderTask list }

type InformationUpdate = {
        Title : string
        Description : string
    }

type FreeOrderUpdate =
    | PlannedExecutionDate of DateTime
    | Information of InformationUpdate
    | Address of Address

type FreeOrderUpdateResponse =
    | Updated
    | FreeOrderIsExecuting
    | FreeOrderIsFinished

type FreeOrdersResponse =
    { FreeOrders : FreeOrder list }

module Helper =
    let stateToString = function
        | FreeOrderState.Undisposed -> "erfasst"
        | FreeOrderState.Disposed -> "aktiv"
        | FreeOrderState.Completed -> "abgeschlossen"