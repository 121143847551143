module Order.FreeOrder.Helper

open Order.FreeOrder.Types
open SharedComponents.DeleteMsgBox
open Validation
open Elmish
open SharedComponents.Toast
open Shared
open SharedComponents
open Routes
open SharedComponents.Badges
open Shared.FreeOrder

let isFreeOrderEditableOrCreatable (userData : UserData) =
    userData.AssignedRoles
    |> List.exists (fun r -> r = Administrator || r = Dispatcher)

let addressToastCmd validation =
    match validation.Validations |> List.tryFind (fun v -> v.Key = "address") with
    | Some v ->
        match v.Value with
        | ValidationState.NotValid e -> toast (ToastType.Error e)
        | Valid -> Cmd.none
    | None -> Cmd.none

let deleteModal (formState : FormState) dispatch =
    match formState with
    | FormState.Edit form ->
        match form.DeleteRequested with
        | None ->
            deleteRequestMessageBox Fable.React.Helpers.nothing
        | Some id ->
            let displayText = sprintf "Wollen Sie den Eigenenauftrag wirklich löschen?"
            deleteRequestMessageBox
                (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteRequest))
                                                (fun _ -> dispatch (AbortDelete))
                                                displayText)
    | FormState.New _
    | FormState.Loading -> Fable.React.Helpers.nothing

module Table =
    open Fable.React.Helpers
    open Fable.React
    open Fable.React.Props

    let detailBtn (freeOrder : FreeOrder) =
        let (FreeOrderId freeOrderId) = freeOrder.Id
        a [ Href (Routes.toPath (Page.FreeOrderDetail freeOrderId) ) ]
            [ i [ Class ("fas fa-eye" ) ] [ ]
              span [ Class "url-text" ] [ str "Anzeigen" ] ]

    let stateBadge (freeOrder : FreeOrder) =
        match freeOrder.State with
        | FreeOrderState.Undisposed -> badgePill "erfasst" BadgeClassType.Warning
        | FreeOrderState.Disposed -> badgePill "aktiv" BadgeClassType.Success
        | FreeOrderState.Completed -> badgePill "abgeschlossen" BadgeClassType.Secondary

    let selectOptions =
        [ "erfasst"
          "aktiv"
          "abgeschlossen" ]

    let selectFilter (selectedValue : string) (freeOrders : FreeOrder[]) =
        if selectedValue = "0" then
            freeOrders
            |> Array.filter (fun order ->
                match order.State with
                | Undisposed -> true
                | Disposed _
                | Completed -> false)
        elif selectedValue = "1" then
            freeOrders
            |> Array.filter (fun order ->
                match order.State with
                | Disposed -> true
                | Undisposed _
                | Completed -> false)
        elif selectedValue = "2" then
            freeOrders
            |> Array.filter (fun order ->
                match order.State with
                | Completed -> true
                | Disposed _
                | Undisposed -> false)
        else freeOrders