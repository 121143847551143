module SharedComponents.BrowserCheck

open Fable.Core
open Fable.Core.JsInterop
open Fable.Import


type BrowserMap =
    { firefox : string }

[<Import("*", from="bowser")>]
let bowser: obj = jsNative

let isInternetExplorer : bool =
    let browser = bowser?getParser(Browser.Dom.window?navigator?userAgent)
    browser?isBrowser("internet explorer")
