module UserManagement.Helper

open UserManagement.Types
open SharedComponents.DeleteMsgBox

let deleteModal (formState : FormState) dispatch =
    match formState with
    | Edit form ->
        match form.DeleteRequested with
        | None ->
            deleteRequestMessageBox Fable.React.Helpers.nothing
        | Some id ->
            let displayText = sprintf "Wollen Sie den Benutzer wirklich löschen?"
            deleteRequestMessageBox
                (deleteRequestMessageBoxContent (fun _ -> dispatch (DeleteRequest))
                                                (fun _ -> dispatch (AbortDelete))
                                                displayText)
    | New _
    | Loading -> Fable.React.Helpers.nothing
