module ErrorHandling


open Elmish
open Elmish.Navigation
open Fable.Import

exception Exception400
exception Exception401
exception Exception403
exception Exception404
exception Exception500

let handleFetchError (e: exn) =
    match e with
    | Exception401
    | Exception403 ->
        Navigation.newUrl (Routes.toPath Routes.Page.NotLoggedInErrorView)
    | Exception400
    | Exception404
    | Exception500
    | _ ->
        Navigation.newUrl (Routes.toPath Routes.Page.FatalErrorView)