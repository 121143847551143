module SharedComponents.Headers

open SharedComponents.Buttons
open Types

open Feliz

type Header () =
    static member subHeader(text : string,
                            ?className : string,
                            ?child : ReactElement) =
        let className = defaultArg className ""
        Html.h3 [
            prop.className (sprintf "form-header-subtitle %s" className)
            prop.children [
                Html.span text
                match child with
                | Some c -> c
                | None -> ()
            ]

        ]

open Fable.React
open Fable.React.Props

let mainHeader content =
    h1 [ Class "form-header-title" ]
      [ str content ]

let mainHeaderWithClassName content className =
    h1 [ Class (sprintf "form-header-title %s" className)]
      [ str content ]

let subHeaderForm content =
    h3 [ Class "form-header-subtitle"]
      [ str content ]

let subHeaderFormWithEdit isEditButtonPressed content editFnct cyEditPostFix saveFnct cySavePostFix undoFnct cyUndoPostFix =
    h3 [ Class "form-header-subtitle"]
      [ str content
        (if isEditButtonPressed then
            span [ ]
              [ saveBtn saveFnct "ml-1" cySavePostFix
                undoBtn undoFnct "ml-1" cyUndoPostFix ]
         else editbtn editFnct "ml-1" cyEditPostFix) ]

let subHeaderFormWithAddButton content fnct =
    h3 [ Class "form-header-subtitle"]
          [ str content
            button [ Class "btn form-header-subtitle-addbtn"
                     OnClick fnct ]
                [ i [ Class "fa fa-plus" ]
               [ ] ] ]

let subHeaderFormWithAddButtonWithEdit isEditButtonPressed content addFnct editFnct cyEditPostFix saveFnct cySavePostFix undoFnct cyUndoPostFix =
    h3 [ Class "form-header-subtitle"]
          [ str content
            (if isEditButtonPressed then
                button [ Class "btn form-header-subtitle-addbtn"
                         OnClick addFnct ]
                    [ i [ Class "fa fa-plus" ]
                   [ ] ]
            else Fable.React.Helpers.nothing)
            (if isEditButtonPressed then
                [ saveBtn saveFnct "ml-1" cySavePostFix
                  undoBtn undoFnct "ml-1" cyUndoPostFix ]
                |> ofList
            else editbtn editFnct "ml-1" cyEditPostFix) ]

let mainHeaderBar customBtns mainHeaderText printFnct deleteFnct =
    div [ Class "d-flex bd-highlight" ]
     [ div [ Class "bd-highlight" ]
        [ mainHeader mainHeaderText ]
       div [ Class "p-2 ml-auto bd-highlight" ]
        [ SharedComponents.Buttons.dropDownBtn customBtns printFnct deleteFnct "ml-auto" ]]

let subHeaderByFormState isEditButtonPressed content editFnct cyPostEditFix saveFnct cyPostSaveFix undoFnct cyPostUndoFix (formState : FormState) =
    if formState = FormState.View then
        subHeaderFormWithEdit isEditButtonPressed content editFnct cyPostEditFix saveFnct cyPostSaveFix undoFnct cyPostUndoFix
    elif formState = FormState.New then
        subHeaderForm content
    else Fable.React.Helpers.nothing

let subHeaderByFormStateWithAddButton isEditButtonPressed content addFnct editFnct cyPostEditFix saveFnct cyPostSaveFix undoFnct cyPostUndoFix (formState : FormState) =
    if formState = FormState.View then
        subHeaderFormWithAddButtonWithEdit isEditButtonPressed content addFnct editFnct cyPostEditFix saveFnct cyPostSaveFix undoFnct cyPostUndoFix
    elif formState = FormState.New then
        subHeaderFormWithAddButton content addFnct
    else Fable.React.Helpers.nothing