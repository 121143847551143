module SharedComponents.ReactSelect

open Fable.Core
open Fable.React
open Fable.Core.JsInterop
open Fable.React.Props
open Fable.Import
open Types
open Types.Helpers

type OptionType<'a> =
  { label : string
    value : 'a}

type OptionsType<'a> = OptionType<'a> array

type CommonProps<'a> =
    | Options of OptionsType<'a>
    | OnChange of (OptionType<'a> option -> unit)
    | Value of OptionType<'a> option
    | IsSearchable of bool
    | Placeholder of string
    | IsDisabled of bool
    | IsMulti of bool
    | IsClearable of bool
    | NoOptionsMessage of (string -> Fable.React.ReactElement)
    | ClassName of string
    | ClassNamePrefix of string

type CommonPropsMultiSelect<'a> =
    | Options of OptionsType<'a>
    | OnChange of (OptionType<'a> array -> unit)
    | Value of OptionsType<'a>
    | IsSearchable of bool
    | Placeholder of string
    | IsDisabled of bool
    | IsMulti of bool
    | IsClearable of bool
    | NoOptionsMessage of (string -> Fable.React.ReactElement)
    | ClassName of string
    | ClassNamePrefix of string


let orderOptions (options : OptionsType<'a>) =
    options |> Array.sortWith (fun a b -> NaturalOrder.sortAsc a.label b.label)

let inline selectLine (props : CommonProps<'a> array) =
    let props =
        props
        |> Array.map (fun prop ->
            match prop with
            | CommonProps.Options options -> options |> orderOptions |> CommonProps.Options
            | _ -> prop)
        |> Array.append [| (CommonProps.ClassNamePrefix "react-select") |]
    ofImport "default" "react-select" (keyValueList CaseRules.LowerFirst props) []

let inline multiSelect (props : CommonPropsMultiSelect<'a> array) =
    let props =
        props
        |> Array.map (fun prop ->
            match prop with
            | CommonPropsMultiSelect.Options options -> options |> orderOptions |> CommonPropsMultiSelect.Options
            | _ -> prop)
        |> Array.append [| (CommonPropsMultiSelect.ClassNamePrefix "react-select") |]
    ofImport "default" "react-select" (keyValueList CaseRules.LowerFirst props) []

let selectWithLabel props (labelType : LabelType) labelClassName =
    let labelText, requiredOption = labelText labelType
    div [ Class "select-with-label form-group d-flex flex-grow-1" ]
        [ label [ Class (sprintf "mt-auto mb-auto mr-3 %s" labelClassName) ]
            [ str labelText
              requiredSpan requiredOption ]
          selectLine props ]

let multiSelectWithLabel props (labelType : LabelType) labelClassName =
    let labelText, requiredOption = labelText labelType
    div [ Class "select-with-label form-group d-flex flex-grow-1" ]
        [ label [ Class (sprintf "mt-auto mb-auto mr-3 %s" labelClassName) ]
            [ str labelText
              requiredSpan requiredOption ]
          multiSelect props ]

let multiSelectWithoutLabel props =
    div [ Class "" ]
        [ multiSelect props ]

let selectWithoutLabel props =
    div [ Class "" ]
        [ selectLine props ]