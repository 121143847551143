module Shared.ServiceOrder

open System
open Shared
open Address
open TaskTypes
open Entity
open RentOrder

type ServiceOrderState =
    | Undisposed
    | Disposed
    | Completed

type ExecutionInformation =
    { PlannedExecutionDate : DateTime
      ExecutionDate : DateTime
      EntityIds : EntityType list }

type ServiceInformation =
    { ContactPerson : string
      FailureDescription : string }

type FollowUpServiceDescription = FollowUpServiceDescription of string

type ServiceOrder =
    { Id : ServiceOrderId
      State : ServiceOrderState
      ContactPerson : string
      FailureDescription : string
      PlannedExecutionDate : DateTime
      ExecutionDate : DateTime
      EntityIds : EntityType list
      ReferencedRentOrderId : RentOrderId option
      PreviousServiceOrderId : ServiceOrderId option
      Priority : PriorityLevel
      ExecutionAddress : Address
      Tasks : ServiceOrderTask list }

type ServiceOrderDto =
    { Id : ServiceOrderId
      State : ServiceOrderState
      ContactPerson : string
      FailureDescription : string
      PlannedExecutionDate : DateTime
      ExecutionDate : DateTime
      Entities : Entity list
      ReferencedRentOrder : RentOrder option
      PreviousServiceOrderId : ServiceOrderId option
      Priority : PriorityLevel
      ExecutionAddress : Address
      Technicans : Employee list
      Tasks : ServiceOrderTask list }

type ServiceOrderOverviewDto =
    { Id : ServiceOrderId
      State : ServiceOrderState
      PlannedExecutionDate : DateTime
      EntityNames : string list
      ExecutionAddress : Address }

type GeneralInformation =
    { PlannedExecutionDate : DateTime }

type ServiceOrderUpdatedResponse =
    | ServiceOrderCompleted
    | ServiceOrderTaskAlreadyAssigned
    | Updated

type ServiceOrderUpdate =
    | GeneralInformation of GeneralInformation
    | BaseInformation of ExecutionInformation
    | FailureDescription of ServiceInformation
    | Priority of PriorityLevel

type ServiceOrdersOverviewResponse =
    { ServiceOrders : ServiceOrderOverviewDto list }

module Helper =
    let stateToString = function
        | ServiceOrderState.Undisposed -> "erfasst"
        | ServiceOrderState.Disposed -> "aktiv"
        | ServiceOrderState.Completed -> "abgeschlossen"
